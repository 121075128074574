/* eslint-disable operator-linebreak */
import { ref, watch, computed } from '@vue/composition-api'
import { getUsersActivity, getUsersActivityPaged } from '@api'
import fetchPaged from '@core/utils/fetchPaged'
import { useUtils } from '@core/libs/i18n'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import useFilters from '@/@core/utils/useFilters'

export default function useUsersActivityList() {
  const { t } = useUtils()
  const {
    configFacility,
  } = useSelectOptions()
  const { updateFilter, getFilterByModule } = useFilters()

  const listTable = ref([])
  const totalListTable = ref(0)
  const computedTableColumns = computed(() => [
    { text: t('transactions.date').toUpperCase(), value: 'register_date' },
    { text: t('fields.hour').toUpperCase(), value: 'register_time', sortable: false },
    { text: t('users.username').toUpperCase(), value: 'user' },
    { text: t('users.fullname').toUpperCase(), value: 'fullname' },
    { text: t('Action').toUpperCase(), value: 'action' },
    { text: t('facilities.facility').toUpperCase(), value: 'facility' },
    { text: t('fields.field').toUpperCase(), value: 'field' },
    { text: `${t('videos.video').toUpperCase()} ID`, value: 'video' },
    { text: `${t('videos.highlight').toUpperCase()} ID`, value: 'highlight' },
    { text: `${t('videos.ip_address').toUpperCase()} ID`, value: 'ip_address' },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: true,
    },
  ])
  const loading = ref(false)
  const options = ref({
    sortBy: ['user'],
    sortDesc: [true],
  })
  const descSort = ref([])
  const filters = ref(null)

  const allowFetch = ref(false)
  const searchQuery = ref('')
  const actionFilter = ref(null)
  const timerSearch = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)

  // fetch data
  const fetchAllUserActivity = async () => {
    const response = await getUsersActivity()
    if (response.ok) return response.data

    return []
  }

  const fetchPagedUserActivity = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (configFacility.value) filterOption.facility_id = configFacility.value
    if (actionFilter.value) filterOption.action = actionFilter.value

    filters.value = filterOption

    const response = await fetchPaged(getUsersActivityPaged, options.value, filterOption)
    listTable.value = response.data
    totalListTable.value = response.total
    loading.value = false
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      if (allowFetch.value) await fetchPagedUserActivity()
    }, 1000)
  }

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      if (allowFetch.value) await fetchPagedUserActivity()
    }
  })

  watch([configFacility], async () => {
    actionFilter.value = null
    searchQuery.value = ''
  })

  watch([configFacility, actionFilter, allowFetch], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    if (allowFetch.value) await fetchPagedUserActivity()
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    updateFilter('views-user-activity-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  return {
    listTable,
    computedTableColumns,
    totalListTable,
    loading,
    options,
    descSort,

    searchQuery,
    actionFilter,

    timerSearch,
    flag,
    allowFetch,

    configFacility,

    t,
    fetchAllUserActivity,
    fetchPagedUserActivity,
    updateFilter,
    getFilterByModule,
  }
}
