<template>
  <div id="user-activity-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('users.user_activity') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-select
            v-model="actionFilter"
            :items="actionsOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('select.action')"
            @change="updateFilter('views-user-activity-list', 'action', actionFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-select
            v-model="actionFilter"
            :items="actionsOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('select.action')"
            @change="updateFilter('views-user-activity-list', 'action', actionFilter)"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="userData && userData.role === 'A' && showExcel && hasPermission(54)"
          cols="12"
          sm="12"
          md="2"
          class="d-flex flex-row"
        >
          <v-btn
            color="secondary"
            block
            @click="exportExcel('/activity_control/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        item-key="id"
        :options.sync="options"
        :loading="loading"
        class="text-no-wrap"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{ sortByText: t('table.sort_by') }"
        :footer-props="{ itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50] }"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :server-items-length="totalListTable"
      >
        <template #[`item.register_date`]="{ item }">
          <div class="d-flex flex-column">
            <span class="text--primary font-weight-semibold text-decoration-none">{{
              formatDate(item.register_date)
            }}</span>
          </div>
        </template>

        <template #[`item.register_time`]="{ item }">
          <div class="d-flex flex-column">
            <span class="text--primary font-weight-semibold text-decoration-none">{{
              fotmatHour(item.register_time)
            }}</span>
          </div>
        </template>

        <template #[`item.action`]="{ item }">
          <v-chip
            small
            color="secondary"
            :class="`error--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.action }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.show') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import { onMounted, ref } from '@vue/composition-api'
import { mdiEyeOutline } from '@mdi/js'
import { useRouter } from '@core/utils'
import { validatePassword } from '@api'

import Swal from 'sweetalert2'
import { error } from '@core/utils/toasted'
import exportExcel from '@core/utils/useExportExcel'

// sidebar
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useUsersActivityList from './useUsersActivityList'

export default {
  setup() {
    const { router } = useRouter()
    const { hasPermission } = usePermissions()
    const { userData } = useCryptoJs()
    const {
      listTable,
      computedTableColumns,
      totalListTable,
      loading,
      descSort,
      options,

      searchQuery,
      actionFilter,
      allowFetch,

      t,
      updateFilter,
      getFilterByModule,
    } = useUsersActivityList()

    const showExcel = ref(true)

    const actionsOptions = ref([
      { text: 'Add Video', value: 'Add Video' },
      { text: 'Create Account', value: 'Create Account' },
      { text: 'Create Highlight', value: 'Create Highlight' },
      { text: 'Open App', value: 'Open App' },
      { text: 'Open Highlight Web', value: 'Open Highlight Web' },
      { text: 'Share Highlight', value: 'Share Highlight' },
      { text: 'Share Video', value: 'Share Video' },
      { text: 'Sign In', value: 'Sign In' },
      { text: 'Sign Out', value: 'Sign Out' },
      { text: 'View Highlight App', value: 'View Highlight App' },
      { text: 'View Video App', value: 'View Video App' },
      { text: 'Video Player Error', value: 'Video Player Error' },
    ])

    const formatDate = dateString => {
      const date = new Date(dateString)

      return date.toISOString().substring(0, 10)
    }

    const fotmatHour = hour => (hour ? hour.substring(0, 5) : '')

    const allowExportExcel = () => {
      Swal.fire({
        title: 'Ingresar contraseña',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: async pass => {
          console.log('preConfirm')

          return validatePassword(pass)
            .then(response => {
              if (response.ok === 0) {
                error(response.message.text)
              }

              return response
            })
            .catch(e => {
              console.error('e', e)
            })
        },
      }).then(async result => {
        if (result.value && result.value.ok) {
          allowFetch.value = true
          showExcel.value = true
          Swal.close()
        } else {
          showExcel.value = false
          router.push({
            name: 'views-order-video-list',
          })
        }
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-user-activity-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
        },
      })
    }

    onMounted(async () => {
      const filtersStored = getFilterByModule('views-user-activity-list')
      if (filtersStored.search) searchQuery.value = filtersStored.search
      if (filtersStored.action) actionFilter.value = filtersStored.action

      allowExportExcel()
    })

    return {
      listTable,
      computedTableColumns,
      totalListTable,
      searchQuery,
      actionFilter,
      actionsOptions,
      loading,
      descSort,
      options,
      showExcel,
      userData,
      onShow,

      formatDate,
      exportExcel,
      allowExportExcel,
      fotmatHour,
      hasPermission,
      updateFilter,

      // i18n
      t,

      // icons
      icons: {
        export: require('@/assets/images/svg/export.svg'),
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.export-excel {
  display: block;
}
</style>
